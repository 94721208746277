import axios from "axios"
import { Toast } from "common/common"
import history from "../history";
//pass new generated access token here
// const token = accessToken
//apply base url for axios
// const API_URL = "https://localhost:44378/api"
// const myhistory = createHistory();
const API_URL = "https://api-beeland.beesky.vn/api"
const axiosApi = axios.create({
  baseURL: API_URL,
})
const config = {
  headers: {
    Authorization:
      "Bearer " + JSON.parse(localStorage.getItem("authUser"))?.acessToken,
  },
}
axiosApi.interceptors.response.use(
  function (response) {
    // console.log(response);
    if (response.status === 200) {
      if (response.data.status !== 2000 ) {
        Toast.error(response.data.message)
      } else if (response.data.isNoti === true) {
        Toast.success(response.data.message)
      }
      return response
    }else if(response.status === 401){
        localStorage.removeItem('authUser');
        history.replace("/login");
        window.location.reload();
    }
   
  },
  error => Promise.reject(error)
)

export async function get(url) {
  return await axiosApi
    .get(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("authUser"))?.acessToken,
      },
    })
    .then(response => response.data)
}

export async function post(url, data) {
  return await axiosApi
    .post(
      url,
      { ...data },
      {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authUser"))?.acessToken,
        },
      }
    )
    .then(response => {
      return response.data
      // if (response.status === 401) {
      //   localStorage.removeItem('authUser');
      //   history.replace("/login");
      //   window.location.reload();
      // }  else{
      //   return response.data
      // }
    })
}
export async function postImg(url, data) {
  try {
    return await axiosApi
      .post(url, data, {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authUser"))?.acessToken,
        },
      })
      .then(response => response.data)
  } catch {}
}
export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data) {
  return await axiosApi
    .delete(
      url,
      { ...data },
      {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authUser"))?.acessToken,
        },
      }
    )
    .then(response => response.data)
}
